<template>
  <div>
    <div class="setting-nav-title">
      {{ type === "online" ? $t("shopOnline") : $t("shopOffline") }}
    </div>
    <div class="shop-search-tools">
      <div class="shop-search-input">
        <el-input v-model="searchString" :placeholder="$t('storeName')" clearable @keyup.enter.native="searchStroe()">
          <el-button slot="append" icon="el-icon-search" v-on:click="searchStroe()"> </el-button>
        </el-input>
      </div>

      <div>
        <el-button
          class="download cancel"
          icon="el-icon-download"
          :loading="btnLoadingOnline"
          :disabled="btnLoadingOnline"
          v-on:click="downloadData"
        >
          {{ $t("downloadShopList") }}
        </el-button>
        <el-button
          v-if="type !== 'online'"
          class="download cancel"
          :loading="btnLoadingDevice"
          :disabled="btnLoadingDevice"
          v-on:click="downloadAllDevicesFile"
          icon="el-icon-download"
        >
          {{ $t("downloadShopDevices") }}
        </el-button>
      </div>
    </div>
    <div class="result-table">
      <el-table :data="data" @row-click="toDetail" v-loading="loading">
        <el-table-column prop="shopName" :label="$t('storeName')"></el-table-column>
        <el-table-column prop="shopId" :label="$t('storeCode')"></el-table-column>
        <el-table-column v-if="type === 'offline'" prop="deviceCount" :label="$t('terminalCount')">
          <template slot-scope="scope">
            {{ numFormat(scope.row.deviceCount) }}
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
              {{ $t("detail") }}
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div v-if="!detailLoading">
          <div class="setting-nav-title">{{ $t("shopInfo") }}</div>

          <div class="dataTable">
            <div class="dataItem">
              <td class="name">{{ $t("storeName") }}</td>
              <td class="value">{{ detailInfo.shopName }}</td>
            </div>
            <div class="dataItem" v-if="type === 'offline'">
              <td class="name">{{ $t("shopReceiptName") }}</td>
              <td class="value">{{ detailInfo.shopReceiptName }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("storeCode") }}</td>
              <td class="value">{{ detailInfo.shopId }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("licenseNo") }}</td>
              <td class="value">{{ detailInfo.licenseNo }}</td>
            </div>
            <div class="dataItem" v-if="type === 'offline'">
              <td class="name">{{ $t("qrCode") }}</td>
              <td class="value"><qrcode :value="detailInfo.qrCode"></qrcode></td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("accountInfo") }}</td>
              <td class="value">{{ detailInfo.bankInfo }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("shopCreateDateTime") }}</td>
              <td class="value">{{ dateFormat(detailInfo.createDateTime) }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("shopTradeTypes") }}</td>
              <td class="value">
                <div class="paylist" v-for="item in detailInfo.tradeTypes" :key="item">
                  <img :src="getPaymentLogos(item)" :data-desc="item" /> <span>{{ item }}</span>
                </div>
              </td>
            </div>
          </div>

          <shop-device v-if="type === 'offline'" :shopId="detailInfo.shopId" />
        </div>
      </div>
    </side-layer>
  </div>
</template>

<script>
import paymentusing from "@/assets/using.png";
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";
/*
:src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName"
*/

import { offlineShops, onlineShops, shopInfo, downloadCPMShop, downloadMPMShop, downloadAllDeviceFile } from "api/shop";
import ShopDevice from "./ShopDevice";
import { executeDownload } from "utils/download";

import { dateFormat } from "utils/date";
import { numFormat, enablePoint } from "utils";
export default {
  props: {
    type: String
    // online, offline
  },
  components: { ShopDevice },
  mixins: [pagination, getPaymentLogos],
  data() {
    return {
      name: "ShopList",
      data: [],
      loading: false,
      btnLoadingOnline: false,
      btnLoadingDevice: false,
      detailDrawer: false,
      detailLoading: true,
      detailInfo: {},
      searchString: "",
      activeNames: ["1"],
      paymentusing
    };
  },
  methods: {
    enablePoint,
    dateFormat,
    numFormat,
    downloadData() {
      this.btnLoadingOnline = true;
      if (this.type === "online") {
        downloadMPMShop({
          shopName: this.searchString
        }).then(response => {
          if (response.data) {
            executeDownload(response);
            this.btnLoadingOnline = false;
            this.$message.success(this.$t("success"));
          } else {
            this.btnLoadingOnline = false;
          }
        });
      } else {
        downloadCPMShop({
          shopName: this.searchString
        }).then(response => {
          if (response.data) {
            executeDownload(response);
            this.btnLoadingOnline = false;
            this.$message.success(this.$t("success"));
          } else {
            this.btnLoadingOnline = false;
          }
        });
      }
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      let action = null;
      this.currentPage = pageIndex;
      this.type === "online" ? (action = onlineShops) : (action = offlineShops);
      let ShopName = this.searchString;
      action({
        ShopName,
        pageIndex,
        pageSize
      }).then(response => {
        this.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.data = response.result;
          this.total = response.totalCount;
        }
      });
    },

    toDetail(row) {
      this.detailDrawer = true;
      this.detailLoading = true;
      this.detailInfo = {};

      let { shopId, isOnline } = row;
      shopInfo({ shopId, isOnline }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
        }
      });
    },
    downloadAllDevicesFile() {
      this.btnLoadingDevice = true;
      downloadAllDeviceFile({ shopName: this.searchString }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnLoadingDevice = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoadingDevice = false;
        }
      });
    },
    searchStroe(pageIndex = 1, pageSize = this.currentPageSize) {
      this.getList(pageIndex, pageSize);
    }
  },

  mounted() {
    this.getList();
  }
};
</script>
<style>
.el-collapse {
  border: none !important;
}
.el-collapse-item__header {
  border-bottom: none !important;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.paylist {
  display: block;
  margin: 4px 0;
  height: 36px;
  line-height: 36px;
  img {
    float: left;
    width: 60px;
    margin-right: 10px;
  }
}
.shop-search-tools {
  @include flex($wrap: wrap);

  .shop-search-input {
    margin-bottom: 20px;
  }

  .el-button.cancel {
    margin-bottom: 20px;
  }
}
</style>
