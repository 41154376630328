<template>
  <div class="shop">
    <div class="panel-title">
      {{ $t("storeManagement") }}
    </div>
    <shop-list type="offline" />
    <shop-list type="online" />
  </div>
</template>

<script>
import ShopList from "./ShopList";

export default {
  name: "Shop",
  components: {
    ShopList
  }
};
</script>
